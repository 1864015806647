<template>
    <div>
        <br />
        <div class="d-md-flex h-100">
            <b-nav vertical pills class="w-100">
                <div class="d-flex justify-content-center mb-4">
                    <b-nav-item to="/"><img src="../assets/demex-logo-big.png" width="154" height="36"
                            alt="Demex" /></b-nav-item>
                </div>
                <template v-for="(n, i) in items">
                    <template v-if="n.children">
                        <b-nav-item v-if="isPermitted(n.permitted || [])" role="button" v-b-toggle="`sub${i}`">{{ n.name
                        }}</b-nav-item>
                        <b-collapse :id="`sub${i}`">
                            <b-nav-item v-if="isPermitted(s.permitted || [])" class="sub" v-for="s in n.children" :data="s"
                                :key="s.name" :to="s.to">{{ s.name }}</b-nav-item>
                        </b-collapse>
                    </template>
                    <template v-else>
                        <b-nav-item v-if="isPermitted(n.permitted || [])" :to="n.to">{{ n.name }}</b-nav-item>
                    </template>
                </template>
            </b-nav>
        </div>
    </div>
</template>

<style scoped>
.nav-link {
    color: #dbd9c1 !important;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav.link:hover {
    color: #fdfbe3 !important;
}

.nav-item {
    border-radius: 8px;
    margin-right: 24px;
}

.sub {
    margin-left: 24px;
}

.nav-item.collapsed .nav-link:after {
    content: "▾";
}

.nav-item.not-collapsed .nav-link:after {
    content: "▴";
}

.nav-item.not-collapsed {
    background-color: RGBA(219, 217, 193, 0.2) !important;
}

a.nav-link.router-link-active {
    text-decoration: underline;
}
</style>

<script>
import { UserRoles } from '../variables/variables.js'
import { mapState } from 'vuex';
import router from '../router'
import { i18n } from '../i18n.js';
export default {
    props: {},
    data() {
        return {
            self: '',
            items: [
                {
                    id: 1,
                    name: this.$t('admin_dashboard_gates'),
                    to: '/gates',
                    children: [
                        {
                            id: 2,
                            name: 'All gates',
                            to: '/gates',
                        },
                        {
                            id: 3,
                            name: 'Devices',
                            to: '/devices',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        }
                    ]
                },
                {
                    id: 4,
                    name: this.$t('admin_dashboard_customers_title'),
                    to: '/customer/customerList',
                    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                },
                {
                    id: 5,
                    name: this.$t('common_users'),
                    to: '/users',
                    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                },
                {
                    id: 6,
                    name: 'Subscriptions/Payments',
                    to: '/subscription/subscriptionList',
                    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                },
                {
                    id: 71,
                    name: 'Settings',
                    to: '/notpermitted',
                    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                    children: [
                        {
                            id: 8,
                            name: 'Gate models',
                            to: '/models',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        },
                        {
                            id: 9,
                            name: 'Translations',
                            to: '/localeStringResources',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        },
                        {
                            id: 10,
                            name: 'Subscription Templates',
                            to: '/subscription/subscriptionTemplateList',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        },
                        //{
                        //    id: 10,
                        //    name: 'Roller',
                        //    to: '/permissions',
                        //    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        //},
                        //{
                        //    id: 11,
                        //    name: 'Mailmallar',
                        //    to: '/mailTemplate/mailTemplateList',
                        //    permitted: [UserRoles.SuperAdmin],
                        //},
                        //{
                        //    id: 12,
                        //    name: 'Dokument',
                        //    to: '/notpermitted',
                        //    permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        //},
                        {
                            id: 13,
                            name: 'Firmware',
                            to: '/firmware',
                            permitted: [UserRoles.SuperAdmin],
                        },
                        {
                            id: 15,
                            name: 'Logs',
                            to: '/log',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        },
                        {
                            id: 16,
                            name: 'Reports',
                            to: '/reports',
                            permitted: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        isPermitted(arr) {
            if (arr.length) return arr.indexOf(this.stateUser.userRole) !== -1;
            else return true;
        }
    },
    computed: {
        ...mapState({
            stateUser: store => store.authentication.user,
        }),
    },
    mounted() {
        this.self = this;
    }
}
</script>