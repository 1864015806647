import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import store from './store/store.js'
import vuetify from './plugins/vuetify';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-icons/font/bootstrap-icons.css'
import axios from "axios";
import i18n from './i18n'
import VueSignalR from '@latelier/vue-signalr'
import * as VueGoogleMaps from 'vue2-google-maps'
// optionally import default styles
//import 'dropzone-vue/dist/dropzone-vue.common.css';


import setupInterceptors from './services/setupInterceptors';

import dmxTitle from './components/dmx-title';
import Multiselect from 'vue-multiselect';
import { ValidationObserver,ValidationProvider } from 'vee-validate';
import './plugins/validation';

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// import './assets/styles/app.css'
import './assets/styles/_app.scss'
import { UserRoles } from '@/variables/variables';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAS71abNbWH9rANEWU-QpzXkHN_0_MQpfo",
        libraries: "places"
    },
    installComponents: true,
});
// DEMEX_URL

Vue.use(VueSignalR, '/gatehub');

import { mapState } from 'vuex';

Vue.mixin({
    computed: {
        ...mapState('authentication', {
            stateUser: 'user',
        }),
    },
    methods: {
        makeToast(title, message, variant = 'success', append = true) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                autoHideDelay: 5000,
                appendToast: append
            })
        },
        utcFormatted(utc) {
            return moment(utc).format('YYYY-MM-DD HH:mm:ss');
        },
        utcFormattedWOtime(utc) {
            return moment(utc).format('YYYY-MM-DD');
        },
        hasGatePermission(perm) {
            if (this.stateUser.userRole == UserRoles.SuperAdmin || this.stateUser.userRole == UserRoles.DemexAdmin)
                return true;

            for (let i in this.stateUser.gates) {
                if (this.stateUser.gates[i].id == this.gate.id) {
                    return this.stateUser.gates[i].userGatePermissions.includes(perm);
                }
            }
            return false;
        },
    }
})

new Vue({
    router,
    store,
    // vuetify,
    i18n,
    render: h => h(App),
    
}).$mount('#app');

// Globally register your component
Vue.component('dmx-title', dmxTitle);
Vue.component('multiselect', Multiselect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

setupInterceptors(store);

